import { HomepagePromise } from 'components/homepagePromises/HomepagePromise'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import Image from 'next/image'
import { ComponentProps } from 'react'
import {
  SCar,
  SContainer,
  SHeader,
  SImageLarge,
  SImageSmall,
  SPromises,
  SPromisesLeft,
  SPromisesRight,
  SPromisesWrapper,
  SRightSide,
  SRing
} from './HomepagePromises.styled'

type PromiseProps = ComponentProps<typeof HomepagePromise>

type Props = {
  variant: 'sell' | 'purchase'
  title: string
  promises: [
    PromiseProps,
    PromiseProps,
    PromiseProps,
    PromiseProps,
    PromiseProps,
    PromiseProps
  ]
}

export function HomepagePromises(props: Props) {
  const { variant, title, promises } = props
  const [promise1, promise2, promise3, promise4, promise5, promise7] = promises

  return (
    <SContainer>
      <SPromises>
        <SCar>
          {[...Array(10)].map((_, i) => (
            <SRing key={i} delay={i} />
          ))}

          <SImageLarge variant={variant}>
            <Image
              src="/images/sell/CarLarge.png"
              layout="responsive"
              width={838}
              height={886}
            />
          </SImageLarge>

          <SImageSmall>
            <Image
              src="/images/sell/SellHomepageCarSmall@2x.png"
              layout="intrinsic"
              width={360}
              height={220}
            />
          </SImageSmall>
        </SCar>
        <SRightSide>
          <SHeader>
            <Spacer axis="vertical" size={4} />
            <TextHeader as="h2" variant={['h5', 'h3', 'h2']}>
              {title}
            </TextHeader>
          </SHeader>
          <SPromisesWrapper>
            <SPromisesLeft>
              <HomepagePromise variant={variant} {...promise1} />
              <HomepagePromise variant={variant} {...promise2} />
              <HomepagePromise variant={variant} {...promise3} />
            </SPromisesLeft>
            <SPromisesRight>
              <HomepagePromise variant={variant} {...promise4} />
              <HomepagePromise variant={variant} {...promise5} />
              <HomepagePromise variant={variant} {...promise7} />
            </SPromisesRight>
          </SPromisesWrapper>
        </SRightSide>
      </SPromises>
    </SContainer>
  )
}
