import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { size } from 'driverama-core/styles/spacing'
import { color, zIndex } from 'driverama-core/styles/variables'
import { inset } from 'driverama-core/styles/inset'
import { media } from 'driverama-core/styles/media'
import { Map } from 'components/map/Map'

export const SContainer = styled.section`
  ${layoutGrid}
`

export const SMap = styled(Map)`
  position: relative;

  grid-column-start: col-left;
  grid-column-end: col-right;

  height: 100vh;
  max-height: ${size(140)};

  margin-top: -${size(12)};

  &::before {
    position: absolute;
    content: '';
    z-index: ${zIndex('order-1')};
    pointer-events: none;

    ${inset(0)};
    background: linear-gradient(
        to bottom,
        ${color('white')} 0%,
        ${color('white', 0)} 25%
      ),
      linear-gradient(to top, ${color('white')} 0%, ${color('white', 0)} 25%);
  }

  .map {
    width: 100%;
    height: 100%;
  }

  @media ${media.gt('tablet')} {
    max-height: ${size(160)};
  }
`
