import {
  FaqQuestionFragment,
  UserReviewFragment
} from 'api/contentful/generated/contentful'
import { Faq } from 'components/faq/Faq'
import { HomepagePromise } from 'components/homepagePromises/HomepagePromise'
import { HomepagePromises } from 'components/homepagePromises/HomepagePromises'
import { Layout } from 'components/layout/Layout'
import { SellWhatNext } from 'components/sellWhatNext/SellWhatNext'
import { UserReviews } from 'components/userReview/UserReviews'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { isRegion } from 'driverama-core/utils/env'
import IconPromise7 from 'images/icons/IconHome.svg'
import IconPromise1 from 'images/icons/sell/IconPromise1.svg'
import IconPromise3 from 'images/icons/sell/IconPromise3.svg'
import IconPromise4 from 'images/icons/sell/IconPromise4.svg'
import IconPromise6 from 'images/icons/sell/IconPromise6.svg'
import IconPromise2 from 'images/icons/sell/IconStep5.svg'
import { ComponentProps, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Footer } from 'sections/footer/Footer'
import { Header } from 'sections/header/Header'
import { SellHomepageHero } from 'sections/homepage/sell/hero/SellHomepageHero'
import { SellHomepageSteps } from 'sections/homepage/sell/steps/SellHomepageSteps'
import { HomepageTradeIn } from 'sections/homepage/tradeIn/HomepageTradeIn'
import { SeoHeader } from 'sections/seo/SeoHeader'
import { useGtmPageView } from 'utils/gtm/useGtmPageView'
import { HomepageMap } from '../map/HomepageMap'
import { SellHomepageMapHeader } from './mapHeader/SellHomepageMapHeader'

type PromiseProps = ComponentProps<typeof HomepagePromise>
type PromisesProps = [
  PromiseProps,
  PromiseProps,
  PromiseProps,
  PromiseProps,
  PromiseProps,
  PromiseProps
]

interface Props {
  reviews: UserReviewFragment[]
  questions: FaqQuestionFragment[]
}

export function SellHomepage({ reviews, questions }: Props) {
  const { t } = useTranslation(['presskit', 'sell'])

  const { logPageView } = useGtmPageView()
  useEffect(() => {
    logPageView({ parameters: { page_data: { locale: 'selling' } } })
  }, [logPageView])

  const promises: PromisesProps = [
    {
      icon: <IconPromise1 />,
      text: t('sell:promise_1'),
      content: t('sell:promise_1_tooltip')
    },
    {
      icon: <IconPromise2 />,
      text: t('sell:promise_2'),
      content: t('sell:promise_2_tooltip')
    },
    {
      icon: <IconPromise3 />,
      text: t('sell:promise_3'),
      content: t('sell:promise_3_tooltip')
    },
    {
      icon: <IconPromise4 />,
      text: t('sell:promise_4'),
      content: t('sell:promise_4_tooltip')
    },
    {
      icon: <IconPromise6 />,
      text: t('sell:promise_6'),
      content: t('sell:promise_6_tooltip')
    },
    {
      icon: <IconPromise7 />,
      text: t('sell:promise_7'),
      content: t('sell:promise_7_tooltip')
    }
  ]

  return (
    <Layout header={<Header variant="transparent" />} footer={<Footer />}>
      <SeoHeader
        title={t('sell:meta_title')}
        description={t('sell:meta_description')}
      />

      <Spacer axis="vertical" size={[2, 5]} />

      <SellHomepageHero />

      <SellHomepageSteps />

      <Spacer axis="vertical" size={[16, 24, 30]} />

      <HomepageMap header={<SellHomepageMapHeader />} />

      <Spacer axis="vertical" size={32} />

      <HomepagePromises
        variant="sell"
        title={t('sell:promise_title')}
        promises={promises}
      />

      <Spacer axis="vertical" size={[16, 42]} />

      {isRegion('de') && !driverama.flags.IS_SELLING_SHUT_DOWN && (
        <>
          <HomepageTradeIn />
          <Spacer axis="vertical" size={[16, 42]} />
        </>
      )}

      <Faq
        title={t('presskit:press_faq_title')}
        subtitle={t('presskit:press_faq_description')}
        questions={questions}
        headingSpacer={<Spacer size={[13, 11, 9]} />}
        actionsSpacer={<Spacer size={[13, 14, 15]} />}
        sourcePage="selling"
      />

      <Spacer axis="vertical" size={[16, 16, 60]} />

      <UserReviews reviews={reviews} />

      <Spacer axis="vertical" size={[16, 16, 44]} />

      <SellWhatNext />

      <Spacer axis="vertical" size={[16, 16, 60]} />
    </Layout>
  )
}
