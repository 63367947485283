import { useEffect, useState } from 'react'
import { useTrustPilotContext } from 'utils/trustPilotContext'
import { AnimatePresence } from 'framer-motion'
import { SContainer, SWrapper } from './SellHomepageHeroTrustpilot.styled'
import { TrustpilotMiniWidget } from 'components/trustpilot/TrustpilotMiniWidget'

export function SellHomepageHeroTrustpilot() {
  const [open, setOpen] = useState(false)

  const { isLoading, isError } = useTrustPilotContext()

  useEffect(() => {
    if (!isLoading && !isError) {
      setOpen(true)
    }
  }, [isLoading, isError])

  return (
    <SWrapper>
      <AnimatePresence>
        {open && (
          <SContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <TrustpilotMiniWidget />
          </SContainer>
        )}
      </AnimatePresence>
    </SWrapper>
  )
}
