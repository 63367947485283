import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { size } from 'driverama-core/styles/spacing'
import { media } from 'driverama-core/styles/media'
import { color, radius } from 'driverama-core/styles/variables'

export const SWrapper = styled.div`
  display: flex;
  justify-content: center;

  position: relative;
  width: 100%;

  border: 2px solid ${color('night-l-800')};
  border-radius: ${radius('corner-mid')};
  padding: ${size(4)};
  margin: ${size(8)} 0;

  @media ${media.gt('tablet')} {
    justify-content: flex-end;

    padding: 0;
    padding-bottom: ${size(10)};
    border: none;
    margin: 0;
  }
`

export const SContainer = styled(motion.div)`
  width: ${size(42)};
`
