import { css } from '@emotion/react'
import { PriceYourCarButton } from 'components/priceYourCarButtons/PriceYourCarButton'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import {
  TextBody,
  TextHeader,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import {
  SButtonContainer,
  SCar,
  SContainer,
  SDetails,
  STrack
} from './SellWhatNext.styled'

export function SellWhatNext() {
  const { t } = useTranslation(['core'])

  return (
    <SContainer>
      <SDetails>
        <TextSubhead>{t('core:what_next_lead')}</TextSubhead>
        <Spacer axis="vertical" size={3} />
        <TextHeader as="h2" variant={['h4', 'h3', 'h2']}>
          {t('core:what_next_title')}
        </TextHeader>
        <Spacer axis="vertical" size={3} />
        <TextBody size={['small', 'large']}>
          {t('core:what_next_content')}
        </TextBody>
        <Spacer axis="vertical" size={11} />

        <SButtonContainer>
          <STrack />
          <PriceYourCarButton
            source="lower"
            css={css`
              @media ${media.lte('tablet')} {
                width: 100%;
              }
            `}
          />
        </SButtonContainer>
      </SDetails>

      <SCar>
        <Image
          src="/images/carFooter@2x.png"
          width={1180}
          height={600}
          quality={100}
        />
      </SCar>
    </SContainer>
  )
}
