import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SWrapper = styled(motion.div)<{ variant?: 'sell' | 'purchase' }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  max-width: ${size(83)};
  margin: ${size(2)} auto;

  color: ${({ variant }) =>
    variant === 'purchase' ? color('night-l-100') : color('sun-d-200')};

  @media ${media.gt('MD')} {
    margin: ${size(2)};
    min-height: ${size(36 + 8)};
  }
`

export const SLabel = styled(TextBody)`
  color: inherit;
  font-size: 15px;
`

export const SIcon = styled.div<{ variant?: 'sell' | 'purchase' }>`
  border-radius: ${radius('corner')};
  display: grid;
  height: ${size(14)};
  margin-right: ${size(4)};
  place-items: center;
  flex-shrink: 0;
  width: ${size(14)};

  ${({ variant }) =>
    variant === 'purchase'
      ? css`
          color: ${color('white')};
          background-color: ${color('night-l-200')};
        `
      : css`
          color: ${color('sun-d-200')};
          background-color: ${color('sun-l-500')};
        `}
`
