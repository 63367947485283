import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'

export const SContainer = styled.section`
  ${layoutGrid};
`

export const SHeader = styled.div`
  grid-column-start: col-left;
  grid-column-end: col-right;

  display: flex;
  flex-direction: column;

  margin: 0 auto ${size(8)};
  max-width: ${size(78)};

  @media ${media.gt('MD')} {
    max-width: initial;
    align-items: center;
  }

  @media ${media.gt('LG')} {
    max-width: ${size(102)};
    margin-left: ${size(0)};
    align-items: center;
  }
`

export const SPromises = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: flex;
  flex-direction: column;
  grid-column-gap: ${size(2)};

  @media ${media.gt('MD')} {
    align-items: center;
  }
  @media ${media.gt('tablet')} {
    display: grid;
    align-items: initial;
    grid-column-start: col-start 1;
    grid-column-end: col-end 12;

    grid-template-areas: 'car right-side';
    grid-template-columns: 1fr 1fr;

    grid-column-gap: ${size(8)};
  }
`

export const SCar = styled.div`
  position: relative;
  grid-area: car;
  margin: 0 auto;

  @media ${media.gt('tablet')} {
    width: ${size(100)};
  }
`

export const SImageLarge = styled.div<{ variant: 'sell' | 'purchase' }>`
  position: absolute;
  top: -${size(20)};
  left: 50%;
  width: 838px;
  height: 886px;

  display: none;

  transform: translateX(-50%)
    ${({ variant }) => (variant === 'purchase' ? 'rotate(180deg)' : '')};

  @media ${media.gt('tablet')} {
    display: block;
  }
`

export const SImageSmall = styled.div`
  @media ${media.gt('tablet')} {
    display: none;
  }
`

export const SRing = styled.div<{ delay: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);

  width: ${size(120)};
  height: ${size(120)};

  border: 2px solid ${color('night-l-650')};
  border-radius: ${radius('full')};

  pointer-events: none;
  animation: pulsate 10s infinite;
  animation-timing-function: linear;
  animation-delay: ${({ delay }) => delay}s;

  @media ${media.gt('tablet')} {
    width: ${size(160)};
    height: ${size(160)};
    border-width: 1px;
  }

  @keyframes pulsate {
    0% {
      opacity: 1;
      border-width: 5px;
      transform: translate(-50%, -50%) scale(0);
    }
    50% {
      opacity: 0.2;
      border-width: 3px;
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
      border-width: 1px;
    }
  }
`

export const SPromisesLeft = styled.div`
  grid-area: promises-l;

  display: flex;
  flex-flow: column;
  align-items: flex-end;
`

export const SPromisesRight = styled(SPromisesLeft)`
  grid-area: promises-r;

  align-items: flex-start;
`
export const SRightSide = styled.div`
  grid-area: right-side;
  display: flex;
  flex-direction: column;
`

export const SPromisesWrapper = styled.div`
  grid-area: right-side;
  display: flex;
  flex-direction: column;

  @media ${media.gt('MD')} {
    flex-direction: row;
  }
`
