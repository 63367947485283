import styled from '@emotion/styled'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { color } from 'driverama-core/styles/variables'

export const SHeader = styled.div`
  grid-column-start: col-left;
  grid-column-end: col-right;

  text-align: center;
`

export const SLead = styled(TextSubhead)`
  color: ${color('night-l-100')};

  @media ${media.gt('tablet')} {
    display: none;
  }
`
