import { SContainer, SMap } from './HomepageMap.styled'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { ReactNode, useMemo, useState } from 'react'
import { LovBranch } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { MapboxAddress } from 'api/mapbox/mapboxTypes'
import { useMedia } from 'react-use'
import { media } from 'driverama-core/styles/media'

type Props = { header: ReactNode }

export function HomepageMap({ header }: Props) {
  const [selectedBranch, setSelectedBranch] = useState<LovBranch>()
  const [selectedAddress, setSelectedAddress] = useState<MapboxAddress>()

  const isDesktop = useMedia(media.gt('tablet'))

  const zoomPadding = useMemo(
    () =>
      isDesktop
        ? { top: 160, bottom: 160, left: 220, right: 220 }
        : { top: 140, bottom: 140, left: 60, right: 60 },
    [isDesktop]
  )

  return (
    <SContainer>
      {header}
      <Spacer size={[14, 20]} grid />
      <SMap
        selectedBranch={selectedBranch}
        selectedAddress={selectedAddress}
        onBranchSelect={setSelectedBranch}
        onAddressSelect={setSelectedAddress}
        zoomPadding={zoomPadding}
      />
    </SContainer>
  )
}
