import styled from '@emotion/styled'
import { color, radius } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { Button } from 'driverama-core/components/button/Button'

export const SContainer = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: grid;

  padding: ${size(6)};

  background-color: ${color('night-d-100')};
  border-radius: ${radius('corner')};

  ${TextHeader} {
    color: ${color('night-l-800')};
  }

  ${TextBody} {
    color: ${color('night-text-light')};
  }

  @media ${media.gt('mobile')} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${size(4)};
    align-items: center;
  }

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 2;
    grid-column-end: col-end 11;

    padding: ${size(16)};
  }
`

export const SImage = styled.div`
  grid-row: 1;
  margin-bottom: ${size(4)};

  @media ${media.gt('mobile')} {
    grid-row: auto;
    transform: scale(1.35) translateX(8%);
    margin-bottom: 0;
  }
`

export const SButton = styled(Button)`
  width: 100%;

  @media ${media.gt('mobile')} {
    max-width: ${size(55)};
  }
`
