import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { SHeader, SLead } from './SellHomepageMapHeader.styled'

export function SellHomepageMapHeader() {
  const { t } = useTranslation(['core', 'sell'])

  return (
    <SHeader>
      <SLead>{t(`sell:map_lead`)}</SLead>
      <Spacer size={[3, 3, 0]} />
      <TextHeader variant={['h5', 'h3', 'h2']} as="h2">
        {t(`sell:map_title`)}
      </TextHeader>
    </SHeader>
  )
}
