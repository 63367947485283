import { css } from '@emotion/react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { ReactNode } from 'react'
import { SIcon, SLabel, SWrapper } from './HomepagePromise.styled'

type Props = {
  icon: ReactNode
  text: string
  content?: string
  className?: string
  variant?: 'sell' | 'purchase'
  footer?: ReactNode
}

export function HomepagePromise(props: Props) {
  const { icon, text, className, content, variant } = props

  return (
    <SWrapper className={className} variant={variant}>
      <div
        css={css`
          padding: ${size(3)};
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <SIcon variant={variant}>{icon}</SIcon>

          <SLabel variant="setup">{text}</SLabel>
        </div>

        <div css={{ textAlign: 'left' }}>
          <Spacer size={4} />

          <TextBody size="large" css={{ color: color('night-text') }}>
            {content}
          </TextBody>

          {props.footer && (
            <>
              <Spacer size={6} />

              {props.footer}
            </>
          )}
        </div>
      </div>
    </SWrapper>
  )
}
