import { PriceYourCarButtons } from 'components/priceYourCarButtons/PriceYourCarButtons'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'react-i18next'
import { SellHomepageHeroTrustpilot } from 'sections/homepage/sell/hero/trustpilot/SellHomepageHeroTrustpilot'
import {
  SContainer,
  SContent,
  SImageContainer,
  SLeft,
  SRight
} from './SellHomepageHero.styled'

export function SellHomepageHero() {
  const { t } = useTranslation('sell')
  const router = useRouter()

  return (
    <SContainer>
      <SLeft>
        <SImageContainer>
          <Image
            src={
              router.locale === 'de'
                ? '/images/sell/hero/hero_de.webp'
                : '/images/sell/hero/hero.webp'
            }
            width={1886}
            height={1728}
            quality={100}
            priority
            layout="responsive"
          />
        </SImageContainer>
      </SLeft>
      <SRight>
        <div />
        <SContent>
          <TextHeader variant={['h5', 'h3', 'h1']}>
            <Trans i18nKey="hero_title" t={t}>
              <br />
            </Trans>
          </TextHeader>
          <Spacer axis="vertical" size={[4, 4, 6]} />
          <TextBody variant="setup">{t('hero_subtitle')}</TextBody>
          <Spacer axis="vertical" size={[4, 4, 6]} />
          <PriceYourCarButtons
            source="upper"
            buttonCss={{ flexGrow: 1, maxWidth: '300px' }}
            hidePlayText
          />
        </SContent>
        <Spacer size={4} />
        <SellHomepageHeroTrustpilot />
      </SRight>
    </SContainer>
  )
}
