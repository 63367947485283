import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import Track from 'images/Track.svg'

export const SContainer = styled.section`
  ${layoutGrid};

  position: relative;
`

export const SDetails = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 8;
    grid-column-end: col-end 12;
  }
`

export const SButtonContainer = styled.div`
  position: relative;
`

export const STrack = styled(Track)`
  position: absolute;
  top: -1850%;
  left: -185%;
  transform: scale(0.35);

  color: ${color('night-l-700')};
  pointer-events: none;

  z-index: -1;

  @media ${media.gt('mobile')} {
    top: -2050%;
    left: -25%;
    transform: scale(0.5);
  }

  @media ${media.gt('tablet')} {
    top: initial;
    bottom: -${size(18)};
    left: -100%;
    transform: scale(1);
  }
`

export const SCar = styled.div`
  grid-column: col-left / col-end 7;
  grid-row: 1;
  pointer-events: none;

  @media ${media.lte('tablet')} {
    grid-column: col-1 / col-4;
    grid-row: 2;

    transform: translateX(-30%) translateY(20%);
  }

  @media ${media.lte('MD')} {
    transform: translateX(-30%) translateY(20%) scale(1.4);
  }
`
