import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'

export const SContainer = styled.section`
  ${layoutGrid}
`

export const SLeft = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  position: relative;
  height: 100%;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-end 7;
  }
`

export const SImageContainer = styled.div`
  transform: scale(1.25) translateX(3.5%);
  transform-origin: center top;

  @media ${media.gt('tablet')} {
    transform: scale(1.25) translateX(-10%);
    transform-origin: top left;
  }
`

export const SRight = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 8;
    grid-column-end: col-end 12;
  }
`

export const SContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
